interface AccordionGroup {
  elements: {
    groups: HTMLElement[];
  };
  init: () => void;
  bindEvents: () => void;
  setupAccordion: (group: HTMLElement) => void;
  handleAccordionClick: (details: HTMLDetailsElement[], targetDetail: HTMLDetailsElement) => void;
}

const accordionGroup: AccordionGroup = {
  elements: {
    groups: Array.from(document.querySelectorAll('.module--accordion-group .module--accordion-group--items')) as HTMLElement[],
  },

  init() {
    this.bindEvents();
  },

  bindEvents() {
    if (this.elements.groups.length > 0) {
      this.elements.groups.forEach(group => {
        this.setupAccordion(group);
      });
    }
  },

  setupAccordion(group: HTMLElement) {
    const details = Array.from(group.querySelectorAll('details')) as HTMLDetailsElement[];

    if (details.length > 0) {
      details.forEach(targetDetail => {
        targetDetail.addEventListener('click', () => this.handleAccordionClick(details, targetDetail));
      });
    }
  },

  handleAccordionClick(details: HTMLDetailsElement[], targetDetail: HTMLDetailsElement) {
    details.forEach((detail) => {
      if (detail !== targetDetail) {
        detail.removeAttribute('open');
      }
    });
  }
};

export default accordionGroup;
