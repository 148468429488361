import { Options } from '@/types/general';

type Elements = {
  module: HTMLDivElement | null;
  selects: HTMLSelectElement[];
  season: HTMLSelectElement | null;
  month: HTMLSelectElement | null;
  type: HTMLSelectElement | null;
  instrument: HTMLSelectElement | null;
  toggle: HTMLButtonElement | null;
};

type SelectedOptions = {
  season: string | undefined;
  month: string | undefined;
  type: string | undefined;
  instrument: string | undefined;
  view: string | undefined;
};

type EventsList = {
  elements: Elements;
  init: () => void;
  bindEvents: () => void;
  updateEvents: (list: string) => void;
  updateCalendar: (calendar: string) => void;
  updateOptions: (select: HTMLSelectElement, options: Options) => void;
  getSelectedOptions: () => SelectedOptions;
  adjustSelectWidth: (selectElement: HTMLSelectElement) => void;
  fetchEvents: (select: HTMLSelectElement) => Promise<void>;
  updateURLParameters: (options: SelectedOptions) => void;
  setInitialStateFromURL: () => void;
};

const eventsList: EventsList = {
  elements: {
    module: null,
    selects: [],
    season: null,
    month: null,
    type: null,
    instrument: null,
    toggle: null,
  },

  init() {
    this.elements.module = document.querySelector('.module--events-list');
    this.elements.selects = Array.from(document.querySelectorAll<HTMLSelectElement>('.module--events-list select'));
    this.elements.season = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-season"]');
    this.elements.month = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-month"]');
    this.elements.type = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-type"]');
    this.elements.instrument = document.querySelector<HTMLSelectElement>('.module--events-list select[data-filter="event-instrument"]');
    this.elements.toggle = document.querySelector<HTMLButtonElement>('.module--events-list button[data-toggle="view"]');

    if(!this.elements.module) return;

    this.setInitialStateFromURL();
    this.bindEvents();
    if (this.elements.season) {
      this.fetchEvents(this.elements.season);
    }
  },

  bindEvents() {
    window.addEventListener('load', () => {
      if (this.elements.selects.length) {
        this.elements.selects.forEach((select) => {
          this.adjustSelectWidth(select);

          select.addEventListener('change', async () => {
            await this.fetchEvents(select);
            this.adjustSelectWidth(select);
          });
        });
      }

      if (this.elements.toggle) {
        this.elements.toggle.addEventListener('click', () => {
          if (!this.elements.module || !this.elements.toggle) return;

          if (this.elements.module.dataset.view === 'list') {
            this.elements.module.dataset.view = 'calendar';
            this.elements.toggle.textContent = 'List View';
          } else {
            this.elements.module.dataset.view = 'list';
            this.elements.toggle.textContent = 'Calendar View';
          }
          this.updateURLParameters(this.getSelectedOptions());
        });
      }
    });

    window.addEventListener('popstate', () => {
      this.setInitialStateFromURL();
      this.fetchEvents(this.elements.season!);
    });
  },

  updateEvents(list: string) {
    const content = this.elements.module?.querySelector<HTMLUListElement>('.module__content--list ul');
    if (!content) return;
    content.innerHTML = list;
  },

  updateCalendar(calendar: string) {
    const content = this.elements.module?.querySelector<HTMLDivElement>('.module__content--calendar');
    if (!content) return;
    content.innerHTML = calendar;
  },

  updateOptions(select: HTMLSelectElement, options: Options) {
    if (!this.elements.month || !this.elements.type || !this.elements.instrument) return;

    switch (select.dataset.filter) {
      case 'event-season':
        this.elements.month.innerHTML = options.months.map((month) => `<option value="${month.value}">${month.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.month);
        this.elements.type.innerHTML = options.types.map((type) => `<option value="${type.value}">${type.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.type);
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
      case 'event-month':
        this.elements.type.innerHTML = options.types.map((type) => `<option value="${type.value}">${type.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.type);
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
      case 'event-type':
        this.elements.instrument.innerHTML = options.instruments.map((instrument) => `<option value="${instrument.value}">${instrument.label}</option>`).join('');
        this.adjustSelectWidth(this.elements.instrument);
        break;
    }
  },

  getSelectedOptions(): SelectedOptions {
    return {
      season: this.elements.season?.value,
      month: this.elements.month?.value,
      type: this.elements.type?.value,
      instrument: this.elements.instrument?.value,
      view: this.elements.module?.dataset.view
    };
  },

  adjustSelectWidth(selectElement: HTMLSelectElement) {
    if (!this.elements.module) return;

    const tmpSelect = document.createElement('select');
    const tmpOption = document.createElement('option');

    tmpOption.textContent = selectElement.options[selectElement.selectedIndex].text;
    tmpSelect.appendChild(tmpOption);

    tmpSelect.style.visibility = 'hidden';
    tmpSelect.style.position = 'absolute';
    tmpSelect.style.width = 'auto';

    this.elements.module.appendChild(tmpSelect);
    const width = tmpSelect.clientWidth;
    this.elements.module.removeChild(tmpSelect);
    selectElement.style.width = `${width}px`;
  },

  async fetchEvents(select: HTMLSelectElement) {
    this.elements.module?.classList.add('loading');

    try {
      const response = await window.jQuery.ajax({
        method: 'POST',
        url: window.curtis.ajaxurl,
        data: {
          action: 'action_get_events',
          selected: this.getSelectedOptions()
        },
      });

      const data = JSON.parse(response) as { options: Options; results: string; calendar: string };
      this.updateOptions(select, data.options);
      this.updateEvents(data.results);
      this.updateCalendar(data.calendar);
      this.updateURLParameters(this.getSelectedOptions());
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      this.elements.module?.classList.remove('loading');
    }
  },

  updateURLParameters(options: SelectedOptions) {
    const searchParams = new URLSearchParams();
    Object.entries(options).forEach(([ key, value ]) => {
      if (value) {
        searchParams.set(key, value);
      }
    });

    const newURL = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newURL);
  },

  setInitialStateFromURL() {
    const searchParams = new URLSearchParams(window.location.search);

    const season = searchParams.get('season');
    if (season && this.elements.season) {
      this.elements.season.value = season;
    }

    const month = searchParams.get('month');
    if (month && this.elements.month) {
      this.elements.month.value = month;
    }

    const type = searchParams.get('type');
    if (type && this.elements.type) {
      this.elements.type.value = type;
    }

    const instrument = searchParams.get('instrument');
    if (instrument && this.elements.instrument) {
      this.elements.instrument.value = instrument;
    }

    const view = searchParams.get('view');
    if (view && this.elements.module) {
      this.elements.module.dataset.view = view;
      if (this.elements.toggle) {
        this.elements.toggle.textContent = view === 'list' ? 'Calendar View' : 'List View';
      }
    }

    if (this.elements.selects.length) {
      this.elements.selects.forEach(select => this.adjustSelectWidth(select));
    }
  },
};

export default eventsList;
